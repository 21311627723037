import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-item-details-card',
  template: `
    <mat-card class="details-card">
      <mat-card-content>
        <div
          *ngFor="let property of item.properties | keyvalue"
          class="field-row"
        >
          <div class="field-label">
            {{ property.value.displayName }}
          </div>
          <div class="field-data">{{ property.value.value }}</div>
        </div>
      </mat-card-content>
    </mat-card>
  `,
  styles: [
    `
      .field-label {
        font-size: 12pt;
        opacity: 0.6;
      }
      .field-data {
        font-size: 16pt;
        padding: 4px 0 0 16px;
      }
      .field-row {
        margin-bottom: 12px;
      }
      .details-card {
        padding-bottom: 25px;
      }
    `
  ]
})
export class DetailsCardComponent implements OnInit {
  @Input() item;
  constructor() {}

  ngOnInit() {}
}
