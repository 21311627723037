import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <mat-toolbar color="primary">
      <span>Inventyler</span>
      <span class="filler"></span>
      <button mat-icon-button routerLink="/add" class="add-button">
        <mat-icon svgIcon="plus-box"></mat-icon>
      </button>
      <button mat-icon-button routerLink="/scan" class="scan-button">
        <mat-icon svgIcon="qrcode-scan"></mat-icon>
      </button>
    </mat-toolbar>
    <router-outlet></router-outlet>
  `,
  styles: [
    `
      mat-toolbar {
        display: flex;
        justify-content: space-between;
      }
      .filler {
        flex-basis: 100%;
      }
    `
  ]
})
export class AppComponent {
  title = 'inventyler';
}
