import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { map, mergeMap, delay, tap } from 'rxjs/operators';
import { ItemsService } from '../items.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-item-page',
  template: `
    <div *ngIf="item$ | async as item">
      <div>
        <div class="temp-toolbar" @slideDown>
          <mat-toolbar color="primary"></mat-toolbar>
        </div>
        <div class="real-toolbar" @fadeIn>
          <app-item-header
            [item]="item"
            [scancode]="scancode$ | async"
          ></app-item-header>
        </div>
      </div>

      <div class="tab-pane" @fadeIn>
        <app-item-details-card [item]="item"></app-item-details-card>
        <div class="mat-small updated-info">
          {{ item.updated.toDate() }}
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .tab-pane {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 12px;
      }
      .updated-info {
        color: #ffffff;
        width: 100%;
        text-align: center;
        margin-top: 10px;
        opacity: 0.55;
      }

      app-item-details-card {
        width: 100%;
      }

      .temp-toolbar {
        position: absolute;
        z-index: -1;
        width: 100%;
      }

      .real-toolbar {
      }
    `
  ],
  animations: [
    trigger('slideDown', [
      transition(':enter', [
        style({ opacity: 1, transform: 'scaleY(0)', transformOrigin: 'top' }),
        animate('300ms ease-in', style({ opacity: 1, transform: 'scaleY(1)' }))
      ])
    ]),
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms 300ms ease-in', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class ItemPageComponent implements OnInit {
  subs: Subscription;
  scancode$: Observable<string>;
  item$: Observable<{} | undefined>;

  constructor(
    private route: ActivatedRoute,
    private itemsService: ItemsService
  ) {
    this.scancode$ = this.route.paramMap.pipe(map(params => params.get('id')));
    this.item$ = this.scancode$.pipe(
      mergeMap(scancode => this.itemsService.getItem(scancode))
    );
  }

  ngOnInit() {}
}
