import { Injectable } from '@angular/core';

import {
  AngularFirestore,
  AngularFirestoreCollection
} from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {
  itemsCollection: AngularFirestoreCollection<any>;

  constructor(private firestore: AngularFirestore) {
    this.itemsCollection = this.firestore.collection('items');
  }

  getItem(scancode: string): Observable<{} | undefined> {
    return this.itemsCollection
      .doc(scancode)
      .snapshotChanges()
      .pipe(map(snapshot => snapshot.payload.data()));
  }

  getItemTypes() {
    return this.firestore
      .collection('types')
      .get()
      .pipe(
        map(snapshot =>
          snapshot.docs.reduce((docs, doc) => {
            docs[doc.id] = doc.data();
            return docs;
          }, {})
        )
      );
  }

  addItem(code: string, itemTypeId) {
    this.itemsCollection.doc(code).set({
      icon: 'file-question',
      name: 'TBD',
      type: itemTypeId,
      updated: new Date(),
      properties: {
        channel: {
          displayName: 'Channel',
          value: '0'
        },
        netId: {
          displayName: 'Network ID',
          value: '0x3141'
        },
        scriptVersion: {
          displayName: 'Script Version',
          value: '2.0.0'
        },
        snapAddr: {
          displayName: 'SNAP Address',
          value: '000000'
        }
      }
    });
  }
}
