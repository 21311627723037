import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-item-header',
  template: `
    <mat-toolbar color="primary">
      <div class="item-header">
        <mat-icon svgIcon="{{ item.icon }}" class="item-icon"></mat-icon>
        <div class="item-name">{{ item.name }}</div>
        <mat-icon class="scan-icon">select_all</mat-icon>
        <div class="scan-code">{{ scancode }}</div>
      </div>
    </mat-toolbar>
  `,
  styles: [
    `
      .item-header {
        height: 64px;
        padding-top: 32px;
        padding-bottom: 42px;
        display: grid;
        grid-template-rows: auto 32px;
        grid-template-columns: 74px 32px auto;
        overflow: hidden;
      }
      .item-icon {
        font-size: 64px;
        width: 64px;
        height: 64px;
        grid-row: 1 / span 2;
        grid-column: 1;
      }
      .item-name {
        grid-row: 1;
        grid-column: 2 / -1;
        align-self: start;
      }
      .scan-icon {
        grid-row: 2;
        grid-column: 2;
        align-self: center;
        justify-self: center;
        opacity: 0.7;
      }
      .scan-code {
        grid-row: 2;
        grid-column: 3;
        align-self: end;
        font-size: 11pt;
        opacity: 0.7;
      }
    `
  ]
})
export class HeaderComponent implements OnInit {
  @Input() item;
  @Input() scancode: string;

  constructor() {}

  ngOnInit() {}
}
