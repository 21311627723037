import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import {
  map,
  scan,
  takeUntil,
  filter,
  exhaustMap,
  reduce
} from 'rxjs/operators';
import { Router } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-scan-page',
  template: `
    <mat-card class="scan-card" [@enterAnimation]>
      <mat-card-title>Start scanning</mat-card-title>
      <mat-card-content>
        <div>Connect the USB barcode scanner and scan a code!</div>
        <div class="spinner"><mat-spinner [diameter]="36"></mat-spinner></div>
      </mat-card-content>
    </mat-card>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
      .scan-card {
      }
      .spinner {
        display: flex;
        justify-content: center;
        padding: 36px 0px 14px;
      }
    `
  ],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(
          '300ms 0ms cubic-bezier(0.4, 0.0, 0.2, 1)',
          style({ opacity: 1 })
        )
      ])
    ])
  ]
})
export class ScanPageComponent implements OnInit, OnDestroy {
  subs: Subscription;

  constructor(private router: Router, private zone: NgZone) {
    const filteredChars = ['Shift'];
    const keyPress$ = fromEvent(document, 'keydown').pipe(
      map((event: KeyboardEvent) => event.key)
    );
    const enterPress$ = keyPress$.pipe(filter(key => key === 'Enter'));

    this.subs = keyPress$
      .pipe(
        exhaustMap(firstKey => {
          return keyPress$.pipe(
            filter(key => !filteredChars.includes(key)),
            takeUntil(enterPress$),
            reduce((code, key) => code + key, firstKey)
          );
        })
      )
      .subscribe(code => {
        // This does not work:
        // this.router.navigate(['/item/', code]);

        // And neither does this (https://github.com/angular/angular/issues/18254):
        // this.zone.run(() => this.router.navigate(['/item/', code]))

        // So I have to do this:
        setTimeout(() => this.router.navigate(['/item/', code]));
      });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
