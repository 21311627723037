import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ScanPageComponent } from './scan-page/scan-page.component';
import { ItemPageComponent } from './item-page/item-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { AddPageComponent } from './add-page/add-page.component';

const routes: Routes = [
  { path: 'add', component: AddPageComponent },
  { path: 'scan', component: ScanPageComponent },
  { path: 'item/:id', component: ItemPageComponent },
  { path: 'login', component: LoginPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
