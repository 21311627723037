// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBRdWM2COmeL0MYJMW_MiRUezErnny5ws8',
    authDomain: 'inventyler-82397.firebaseapp.com',
    databaseURL: 'https://inventyler-82397.firebaseio.com',
    projectId: 'inventyler-82397',
    storageBucket: '',
    messagingSenderId: '291744118741',
    appId: '1:291744118741:web:79ca24c7ce4b3e81'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
