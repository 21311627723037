import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { fromEvent, Subscription, iif } from 'rxjs';
import {
  map,
  filter,
  exhaustMap,
  takeUntil,
  reduce,
  mergeMap,
  tap
} from 'rxjs/operators';
import { ItemsService } from '../items.service';

@Component({
  selector: 'app-add-page',
  template: `
    <mat-card class="scan-card" [@enterAnimation]>
      <mat-card-title>Add new item</mat-card-title>
      <mat-card-content>
        <div>
          Connect the USB barcode scanner and scan a code to add a new item!
        </div>
        <div class="spinner" *ngIf="codes.length === 0">
          <mat-spinner [diameter]="36"></mat-spinner>
        </div>
        <div class="code-list" *ngIf="codes.length > 0">
          Scanned codes:
          <div class="scanned-code-item" *ngFor="let code of codes">
            <div>
              {{ code }}
              <mat-form-field>
                <mat-label>Item type</mat-label>
                <mat-select #selectedItemType>
                  <mat-option
                    *ngFor="let itemType of itemTypes | keyvalue"
                    [value]="itemType.key"
                  >
                    {{ itemType.value.displayName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button (click)="onAdd(code, selectedItemType.value)">Add</button>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
      .scan-card {
      }
      .spinner {
        display: flex;
        justify-content: center;
        padding: 36px 0px 14px;
      }
      .code-list {
        padding: 16px;
      }
      .scanned-code-item {
      }
    `
  ],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(
          '300ms 0ms cubic-bezier(0.4, 0.0, 0.2, 1)',
          style({ opacity: 1 })
        )
      ])
    ])
  ]
})
export class AddPageComponent implements OnInit, OnDestroy {
  codes: string[] = [];
  itemTypes = {};
  subs: Subscription;
  constructor(private itemsService: ItemsService) {
    const filteredChars = ['Shift'];
    const keyPress$ = fromEvent(document, 'keydown').pipe(
      map((event: KeyboardEvent) => event.key)
    );
    const enterPress$ = keyPress$.pipe(filter(key => key === 'Enter'));
    const codeScan$ = keyPress$.pipe(
      exhaustMap(firstKey => {
        return keyPress$.pipe(
          filter(key => !filteredChars.includes(key)),
          takeUntil(enterPress$),
          reduce((code, key) => code + key, firstKey)
        );
      })
    );

    this.subs = codeScan$
      .pipe(
        mergeMap(scancode =>
          this.itemsService.getItem(scancode).pipe(
            map(result => {
              return { code: scancode, alreadyExists: Boolean(result) };
            })
          )
        ),
        tap(item => console.log(item))
      )
      .subscribe(item => {
        if (!this.codes.find(code => code === item.code)) {
          if (item.alreadyExists) {
            this.codes.push(item.code + ' already exists.');
          } else {
            this.codes.push(item.code);
          }
        }
      });

    this.subs.add(
      this.itemsService.getItemTypes().subscribe(itemTypes => {
        console.log(itemTypes);
        this.itemTypes = itemTypes;
      })
    );
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onAdd(code, itemType) {
    console.log(code);
    this.itemsService.addItem(code, itemType);
  }
}
